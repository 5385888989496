import React from 'react'

import XLSX from 'sheetjs-style';
import Coreografia from '../../Coreografia/Coreografia';



const ExportExcel = ({coreografias}) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportaExcel = async () => {
    const exportData = coreografias.map((coreografia) => {
      const participantes = coreografia.participantes.map((participante) => {
        return `${participante.nombre} ${participante.apellido}`
      }).join(', ');
      return {
        id: coreografia.id,
        academia: coreografia.academia,
        forma: coreografia.formasParticipacion,
        categoria: coreografia.categoria,
        modalidad: coreografia.modalidad,
        genero: coreografia.genero,
        eliminatoria: coreografia.eliminatoria,
        puesto: coreografia.puesto,
        instancia: coreografia.instancia,
        participantes: participantes,
        director: coreografia.director,
        email: coreografia.email,
        telefono: coreografia.user.telefono,
        telefono1: coreografia.user.celular,

      }
    })
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = {Sheets: {'data': ws}, SheetsNames: ['data']};
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Dates");
    XLSX.writeFile(workbook, "Listado de Inscripciones.xlsx", { compression: true });

  }
  return (
    <>
      <div className="button-container">
        <button className='button' onClick={exportaExcel}>
          Exportar a Excel
        </button>
      </div>

    </>
  )
}

export default ExportExcel