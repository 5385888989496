import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Modal.css'

const Modal = ({children, title, handleClose, visible, size=''}) => {

  return (
    visible &&
    <div className='modal-background' id='modal-bg'>
      <div className={`modal ${size}`} id='modal-close'>
        <div className='modal-container'>
        <FontAwesomeIcon icon='fa-solid fa-xmark' className='modal-close' onClick={handleClose}/>
          <div className='modal-title'>
            {title}
          </div>
          <hr/>
          <div className='modal-content'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal