import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from '../../api/axios'
import logo from '../../assets/images/logo.png'
import '../Registro/Registro.css'
import './Confirma.css'

const Confirma = () => {
  const {token} = useParams();
  const navigate = useNavigate();
  const [confirmado, setConfirmado] = useState();
  
  useEffect(()=> {
    if(token){
      checkToken();
    }

  },[])
  const checkToken = async () => {
    const result = await axios.get(`${process.env.REACT_APP_BASE_URI}users/confirm/${token}`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        });
        if(result.data.message==='User confirmed'){
          navigate('/login')
        }
  }
  const handleClick = () => {
    navigate('/login')
  }
  return (
    <main className="main-container">
        <div className="login-content">
            <img className="login-logo" src={logo} alt='logo'></img>
        </div>
        <div className="login-container-recupero">
          <h1 className='title'>REGISTRO EXITOSO</h1>
          <p className='text'>Revise su correo para confirmar el registro</p>
          <button className="login-button" type="button" onClick={handleClick}>Volver</button>
        </div>
        <footer>
            <p className="footer-text">Desarrollado por ZAZ</p>
        </footer>
    </main>
  )
}

export default Confirma