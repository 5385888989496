import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom"
import { data } from "../../../data/menuData";
import './Menu.css'

const Menu = ({toggle}) => {
  const [sideMenu, setSideMenu] = useState(data['side-menu']);
  const [bottomMenu, setBottomMenu] = useState(data['bottom-menu']);

  return (
    <div className="menu-container">
      <div className="menu-content">
        <ul className="menu-list">
          {sideMenu.map((element, index) => {
          const name = `fa-solid fa-${element.iconName}`;
          return (
            <li className="sidebar-list-item" key={index} onClick={toggle}>
              <Link to={`/${element.title.toLocaleLowerCase()}`}  >
                <FontAwesomeIcon icon={name} className='sidebar-list-item-icon'/>
                <p className="sidebar-list-item-text">{element.title}</p>
              </Link>
            </li>    
            )
        })}
        {bottomMenu.map((element, index) => {
          const iconName = `fa-solid fa-${element.iconName}`;
          return (
            <Link to ={`/${element.title.toLocaleLowerCase()}`} className='exit-container' key={index}>
              <FontAwesomeIcon icon={iconName} className='sidebar-list-item-icon'/>
              <p className="sidebar-list-item-text">{element.title}</p>
            </Link>
          )
        })
        }
        </ul>

      </div>

    </div>
  )
}

export default Menu