import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import './Participante.css';

const Participante = ({data, handleClick}) => {
  const {auth} = useAuth()
  const {id, nombre, apellido, dni, fechaNacimiento, edad, _id , user} = data;
  const nacimiento = dayjs(fechaNacimiento);
  return (
    <tr className="table-row">
    <td className="table-cell">{id}</td>
    <td className="table-cell">{`${apellido}, ${nombre}`}</td>
    <td className="table-cell">{dni}</td>
    <td className="table-cell">{nacimiento.format('DD/MM/YYYY')}</td>
    <td className="table-cell">{edad}</td>
    {auth.role === 'admin' && <td className="table-cell">{user.compania}</td>}
    <td className="table-cell">
      {/* <button type='button' className='action-button' id={`editar-${_id}`} onClick={handleClick}>
        <FontAwesomeIcon icon='fa-solid fa-pen-to-square' className='success' />
      </button> */}
      <button type='button' className='action-button' id={`eliminar-${_id}`} onClick={handleClick}>
        <FontAwesomeIcon icon='fa-solid fa-trash' className='danger' />
      </button>
    </td>
  </tr>
  )
}

export default Participante