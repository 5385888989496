import './Card.css'

const Card = ({title, data}) => {
  return (
    <div className="card">
      <div className="card-content">
        <p className="card-title">{title}</p>
        <h3 className="card-text">{data}</h3>
      </div>
    </div>
  )
}

export default Card