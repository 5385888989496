import React from 'react'
import {Perfil as PerfilForm} from '../components/Forms/Perfil/Perfil'

const Perfil = () => {
  return (
    <div>
      <PerfilForm/>
    </div>
  )
}

export default Perfil