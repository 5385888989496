import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Users = () => {
  const {auth} = useAuth()
  const [users, setUsers] = useState([]);
  const axiosPrivate = useAxiosPrivate();

const getUsers = async () => {
  const result = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}users/`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        });
        if(result?.data?.success){
          const filteredUsers = result.data.data.filter((item) => item.status === 'Active' && item.role !== 'admin');
          setUsers(filteredUsers);
        }
}
const getParticipantes = async (id) => {
  const result = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}coreografias/${id}`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        });
        if(result?.data?.success){
          console.log('PARTC',result.data.data.length)
          return result.data.data.length;
        }
}
const getCoreografias = async (id) => {
  const resultParticipantes = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}participantes/${id}`)
  .catch((err)=> {
    console.log('ERROR EN GET', err)
  });
  if(resultParticipantes?.data?.success){
    return resultParticipantes.data.data.length;
  }
}

useEffect(()=> {
  getUsers();
},[])
  return (
    <div>
      <table className="table">
        <thead className="table-header">
          <tr className="table-row">
            <th className="table-title">Academia</th>
            <th className="table-title">Director</th>
            <th className="table-title">Telefono</th>
            <th className="table-title">Provincia</th>
            <th className="table-title">Email</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {
            users.map( (user) => {
              return  <tr key={user.email} className="table-row">
                        <td className='table-cell'>{user.compania}</td>
                        <td className='table-cell'>{user.nombre} {user.apellido}</td>
                        <td className='table-cell'>{user.telefono}</td>
                        <td className='table-cell'>{user.provincia}</td>
                        <td className='table-cell'>{user.email}</td>
                      </tr>
              })
          }
        </tbody>
        </table>
    </div>
  )
}

export default Users