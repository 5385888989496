import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from '../../hooks/useAuth';
import './Coreografia.css'

const Coreografia = ({data, handleClick}) => {
  const {auth} = useAuth()
  const {id, modalidad, formasParticipacion, categoria, participantes, monto, enviado, _id, academia} = data;
  return (
    <tr className={enviado?'table-row bg-success':'table-row'}>
      <td className="table-cell">{id}</td>
      <td className="table-cell">{modalidad.toUpperCase()}</td>
      <td className="table-cell">{formasParticipacion.toUpperCase()}</td>
      <td className="table-cell">{categoria.toUpperCase()}</td>
      <td className="table-cell">{participantes.length}</td>
      <td className="table-cell display-none">${monto}</td>
      {auth.role === 'admin' && <td className="table-cell">{academia}</td>}
      <td className="table-cell actions-cell">
      {
        auth.role==='admin' &&
        <button className={!enviado?'action-button info':'display-none'} onClick={handleClick} id={`enviar-${_id}`}><FontAwesomeIcon  icon='fa-solid fa-paper-plane'  /> Enviar</button>
      }
        
        <a href={`${process.env.REACT_APP_BASE_URI}files/Inscripcion ${String(id).padStart(5,'0')}.pdf`} target="_blank" rel="noreferrer"><FontAwesomeIcon onClick={handleClick} icon='fa-solid fa-print' className='action-button' id={`print-${_id}`}/></a>
        {/* <FontAwesomeIcon onClick={handleClick} icon='fa-solid fa-pen-to-square' className='action-button success' id={`edit-${_id}`}/> */}
        {
        auth.role==='admin' &&
        <FontAwesomeIcon onClick={handleClick} icon='fa-solid fa-trash' className={!enviado?'action-button danger':'display-none'} id={`delete-${_id}`}/>
      }
      </td>
  </tr>
  )
}

export default Coreografia