import { Route, Routes } from 'react-router-dom';
import Login from "./components/Login/Login";
import Recupero from './components/Recupero/Recupero';
import Registro from "./components/Registro/Registro";
import Layout from './components/Layout/Layout';
import Home from './pages/Home';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import Participante from './pages/Participante';
import Salir from './pages/Salir';
import Perfil from './pages/Perfil';
import RequireAuth from './components/RequireAuth.js/RequireAuth';
import Confirma from './components/Confirma/Confirma';
import RecuperoConfirmado from './components/Recupero/RecuperoConfirmado';
import Administracion from './pages/Administracion';
library.add(fas)
function App() {
  return (
    <Routes>
      <Route path="/registro" element={<Registro/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/recupero" element={<Recupero/>}/>
      <Route path="/recupero/:codeId" element={<RecuperoConfirmado/>}/>
      <Route path="/success" element={<Confirma/>}/>
      <Route path="/success/:token" element={<Confirma/>}/>
      <Route path="/salir" element={<Salir/>}/>
      <Route element={<Layout/>}>
        <Route element={<RequireAuth/>}>
          <Route path="/" element={<Home/>}/>
          <Route path="/inscripciones" element={<Home/>}/>
          <Route path="/participantes" element={<Participante/>}/>
          <Route path="/perfil" element={<Perfil/>}/>
          <Route path="/administracion" element={<Administracion/>}/>
        </Route>
      </Route>
    </Routes>
      
    
  );
}

export default App;
