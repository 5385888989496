import React from 'react'
import ExportExcel from '../components/Exports/ExportExcel/ExportExcel'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { toast } from 'react-toastify'
import Users from '../components/Users/Users'

const Administracion = () => {
  const axiosPrivate = useAxiosPrivate()
  const {auth} = useAuth()
  const downloadPath= 'https://api.awldc.dalproducciones.com.ar/download/MusicaAWLDC.zip'

  function downloadFile(filePath){
    var link=document.createElement('a');
    link.href = filePath;
    link.target = '_blank';
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
    //document.removeChild(link);
}

  

  const descargaMusica = async () => {
    
    const result = await toast.promise(axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}coreografias/musica`),
    {
      pending: "Descargando archivos",
      success: "Descarga correcta"
    }

    ) 
    if(result){
      console.log(downloadPath);
      downloadFile(downloadPath)
    }
  }
  return (
    <>
    <div className='users-container'>
     <div className="button-container">
        <button className='button' onClick={descargaMusica}>
          Descargar Musica
        </button>
      </div>
      <Users />
    </div>
    </>
  )
}

export default Administracion