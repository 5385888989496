import { faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import axios from '../../api/axios'
import logo from '../../assets/images/logo.png'
import '../Registro/Registro.css'



const RecuperoConfirmado = () => {
    const initialValues = {password: '', passwordConfirm:''};
    const [password, setPassword] = useState(initialValues)
    const {codeId} = useParams();
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(password.password===''){
            toast.error('Debe ingresar una contraseña',{
              onClose: () => setPassword(initialValues)
            });
        }else{
          if( password.password.length < 8) {
            toast.error('La contraseña debe tener minimo 8 caracteres',{
              onClose: () => setPassword(initialValues)
            });
          }else {
            if( password.password !== password.passwordConfirm) {
              toast.error('Las contraseñas no coinciden',{
                onClose: () => setPassword(initialValues)
              });
  
            }else {
              const result = await axios.post(`${process.env.REACT_APP_BASE_URI}users/forgot/${codeId}`,JSON.stringify(password,null,2), {withCredentials:true, headers:{'Access-Control-Allow-Origin':'*', 'Content-Type':'application/json'}})
              .catch((err)=> {
               return err
              })
              if(result){
                toast.success('Perfecto! Ahora ingrese con su nueva contraseña',{
                  onClose: () => navigate('/login'),
                  autoClose: 3000
                });
                
              }
            }
          }
      }
        
        const passInput = document.querySelector('#password')
        passInput.value='';
        const passConfirmInput = document.querySelector('#passwordConfirm')
        passConfirmInput.value='';
          console.log(password);
        
        }
    const handleChange = (e) => {
        setPassword({...password, [e.target.name]:e.target.value});
    }
  return (
    <main className="main-container">
        <div className="login-content">
            <img className="login-logo" src={logo} alt='logo'></img>
            <p className="login-content-text"><span className="login-title">Sistema online exclusivo para PROFESORES</span>
                <br/>
                <br/>

                Hemos diseñado un sistema AUTOGESTIÓN PROFESORES totalmente automatizado para ayudarte en toda tu organización y ahorrarte tiempo. Si tiene una cuenta debe ingresar los datos, sino la tiene, primero debe registrarse y luego recibirá un email para activarla y hacer uso de todas las funciones del sistema.
                Aquí podrá ingresar a todos los participantes, enviar los videos de clasificación, cargar coreografías, adjuntar la música, abonar aranceles y/o adjuntar comprobantes de pago. Una vez finalizada su participación podrá consultar los puntajes, devoluciones de jurados y descargar certificados digitales entre otras cosas.</p>
        </div>
        <div className="login-container-recupero">
            <form className="login-form" id="form" action="#">
                <input className="login-input" type="password" name="password" id="password" placeholder="Contraseña" required onChange={handleChange} ></input>
                <input className="login-input" type="password" name="passwordConfirm" id="passwordConfirm" placeholder="Confirmar Contraseña" required onChange={handleChange} ></input>
                <input className="login-button" type="submit" value="Cambiar clave" onClick={handleSubmit}></input>
            </form>
        </div>
        <footer>
            <p className="footer-text">Desarrollado por ZAZ</p>
        </footer>
        <ToastContainer
       
        />
    </main>
  )
}

export default RecuperoConfirmado
