import React, { useState } from 'react'
import '../Registro/Registro'
import logo from '../../assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom'
import {useFormik} from 'formik';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import { faIndianRupee } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';

const LOGIN_URL = 'users/login';
const validationSchema = yup.object({
    username: yup
        .string('Ingrese email')
        .required('Es necesario que ingrese su email'),
    password: yup
        .string('Ingrese su contraseña')
        .min(6,'Password debe tener 6 caracteres como mínimo')
        .required('Es necesario que ingrese su password')
})


const Login = () => {
  const navigate = useNavigate();
  const {setAuth} = useAuth();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const from = location.state?.from?.pathname || "/";
  
  const formik = useFormik({
    initialValues: {
      username:'',
      password:'',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await toast.promise(axios.post(`${process.env.REACT_APP_BASE_URI}${LOGIN_URL}`,JSON.stringify(values,null,2), {withCredentials:true, headers:{'Access-Control-Allow-Origin':'*', 'Content-Type':'application/json'}})
        .catch((err)=> {
          if(err.response.data === 'Unauthorized') {
            setErrorMessage ("Intente nuevamente, Email o Contraseña incorrecta");
          } else {
            setErrorMessage (err.response.data.message);
          }
        }),
        {
          pending: 'Iniciando sesion',
        }
      )
        if(result.data.success) {
          const accessToken = result?.data?.token;
          const nombre = result?.data?.nombre;
          const id = result?.data?.id;
          const role = result?.data?.role
          setAuth({accessToken,nombre, id, role});
          navigate(from, {replace:true});
        }
    }
  })
  const handleRegister = () => {
    navigate('/registro');
  }
  const handleOlvido = () => {
    navigate('/recupero');
  }
  const handleIngresar = () => {
    navigate('/');
  }
  return (
    <div className='main-container'>
      <div className='login-content'>
        <img className='login-logo' src={logo} alt='Logo'/>
        <p className='login-content-text'><span className="login-title">Sistema online exclusivo para PROFESORES</span><br></br>
        Hemos diseñado un sistema AUTOGESTIÓN PROFESORES totalmente automatizado para ayudarte en toda tu organización y ahorrarte tiempo. Si tiene una cuenta debe ingresar los datos, sino la tiene, primero debe registrarse y luego recibirá un email para activarla y hacer uso de todas las funciones del sistema.
                Aquí podrá ingresar a todos los participantes, enviar los videos de clasificación, cargar coreografías, adjuntar la música, abonar aranceles y/o adjuntar comprobantes de pago. Una vez finalizada su participación podrá consultar los puntajes, devoluciones de jurados y descargar certificados digitales entre otras cosas.</p>
      </div>
      <div className="login-container">
        <form onSubmit={formik.handleSubmit} className='login-form'>
          <small className='error-msg'>{errorMessage}</small>
          <input className="login-input" type="email" name="username" id="username" placeholder="Email" required onChange={formik.handleChange} error={formik.touched.username && Boolean(formik.errors.username)}></input>
          <input className="login-input" type="password" name="password" id="password" placeholder="Clave" required onChange={formik.handleChange} error={formik.touched.password && Boolean(formik.errors.password)}></input>
          <p className="login-text" id="forget-pass" onClick={handleOlvido}>¿Olvido su clave?</p>
          <button className="login-button" type="submit" >Ingresar</button>
          <p className="login-text">¿No tiene cuenta? <span className="login-register-button" onClick={handleRegister}>Registrese aqui</span></p>
        </form>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Login