import logo from '../../../assets/images/logo.png';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import useAuth from '../../../hooks/useAuth';
import Menu from '../Menu/Menu';
import { useState } from 'react';

const Navbar = () => {
  const {auth} = useAuth(); 
  const [visible, setVisible] = useState(false)
  const handleClick = (e) => {
    setVisible(!visible)
  }
  return (
    <header id="cabecera">
      <a id="logo-cabecera" href="/">
        <img className="logo-cabecera" src={logo} alt='logo'></img>
      </a> 
      <div className="user-container">
        <p className="titulo-cabecera"><span className="titulo-pagina-costado">Bienvenido {auth.nombre} {auth.role=== 'admin' && `(${auth.role})`}</span></p>
      </div>
      <div className='menu-icon-container'>
        <FontAwesomeIcon icon={solid("bars")} size='lg' onClick={handleClick} />
      </div>
      {visible && <Menu toggle={handleClick}/>}
    </header>
  )
}

export default Navbar