import { Ring } from '@uiball/loaders'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../hooks/useAxiosPrivate'


const Salir = () => {
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const logout =  async() => {
    const login = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}users/logout`).catch((err) => toast.error(err.response.data.message))
    if(login){
      navigate('/login');
    }
  }
  useEffect(()=>{
    logout();
  },[])
  return (
    <Ring/>
  )
}

export default Salir