import './Sidebar.css';
import {data} from '../../../data/menuData';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';


const Sidebar = ({children}) => {
  const [topMenu, setTopMenu] = useState(data['top-menu']);
  const [sideMenu, setSideMenu] = useState(data['side-menu']);
  const [bottomMenu, setBottomMenu] = useState(data['bottom-menu']);
  const navigate = useNavigate;
  const {auth} = useAuth(); 
  
  const handleClick = (e) => {
    
  }
  
  return (
    <div className='layout-container'>
      <aside className="sidebar">
      <ul className="sidebar-list-container">
        {sideMenu.map((element, index) => {
          let show = false;
          
          if( element.role === auth.role || auth.role === 'admin'){
            show = true
          }
          const name = `fa-solid fa-${element.iconName}`;
          if(show){
            return (
              <li className="sidebar-list-item" key={index}>
                <NavLink to={`/${element.title.toLocaleLowerCase()}`} >
                  <FontAwesomeIcon icon={name} className='sidebar-list-item-icon'/>
                  <p className="sidebar-list-item-text">{element.title}</p>
                </NavLink>
              </li>    
              )
          }else {
            return (<li></li>)
          }

        })}
      </ul>
      <div className="sidebar-buttons">
        {bottomMenu.map((element, index) => {
          const iconName = `fa-solid fa-${element.iconName}`;
          return (
            <Link to ={`/${element.title.toLocaleLowerCase()}`} className='exit-container' key={index}>
              <FontAwesomeIcon icon={iconName} className='sidebar-list-item-icon'/>
              <p className="sidebar-list-item-text">{element.title}</p>
            </Link>
          )
        })
        }
      </div>
    </aside>
    <div className='container'>
      {children}
    </div>
    </div>
  )
}

export default Sidebar