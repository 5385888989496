import React from 'react'
import './Message.css'

const Message = ({closeToast, handleDelete, id}) => {
  return (
    <div className='message-container'>
    <p className='message-text'>Seguro desea eliminar?</p>
    <div className='message-button-group'>
      <button className='message-button toast-danger' onClick={() => handleDelete(id)}>Si</button>
      <button className='message-button light' onClick={closeToast}>No</button>
    </div>
    
  </div>
  )
}

export default Message