import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import { Ring } from '@uiball/loaders'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import Card from '../Card/Card'
import './CardList.css'

const cards = [
  {title:'Integrantes', data: '24'},
  {title:'Inscripciones enviadas', data: '2'},
  {title:'Inscripciones borrador', data: '1'},
]

const CardList = () => {
  const [cards, setCards] = useState([])
  const axiosPrivate = useAxiosPrivate()
  const {auth} = useAuth()
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    const newData = [];
    const resultParticipantes = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}participantes/${auth.id}`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        });
        if(resultParticipantes?.data?.success){
          newData.push({title: 'Integrantes', data:resultParticipantes.data.data.length})
        }
    const result = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}coreografias/${auth.id}`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        });
        if(result?.data?.success){
          const participantes = result.data.data;
          const total = participantes.length;
          const filtered = participantes.filter((participante)=> participante.enviado === true)
          const enviados = filtered.length;
          const noEnviados = total - enviados;
          newData.push({title: 'Inscripciones enviadas', data:enviados})
          newData.push({title: 'Inscripciones no enviados', data:noEnviados})
        }
        
        setCards(newData);
        setLoading(false);
  }
  useEffect(()=>{
    setLoading(true)
    getData();
  },[])
  return (
    <>
    { loading?
      <Ring />
    :
      <div className="card-container">
        {cards.map((card, index) => {
          return (
            <Card title={card.title} data={card.data} key={index}/>
          )
        })}
      </div>
    }
    </>
  )
}

export default CardList