import CoreografiaList from '../CoreografiaList/CoreografiaList';
import CardList from './CardList/CardList';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <section className="listadoBorrador-container">
      <h1 className="section-title">CAMPEONATO ARGENTINA WORLD LATIN DANCE CUP</h1>
      <h2 className="section-subtitle">COREOGRAFIAS</h2>
      <CardList/>
      <CoreografiaList/>
    </section>
  )
}

export default Dashboard