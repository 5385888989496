import { faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import axios from '../../api/axios'
import logo from '../../assets/images/logo.png'
import '../Registro/Registro.css'



const Recupero = () => {
    const [email, setEmail] = useState('')
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email===''){
            toast.error('Debe ingresar un correo válido');
        }else{
            const result = await axios.post(`${process.env.REACT_APP_BASE_URI}users/forgot`,JSON.stringify({email},null,2), {withCredentials:true, headers:{'Access-Control-Allow-Origin':'*', 'Content-Type':'application/json'}})
            .catch((err)=> {
             return err
            })
        }
        
        const emailInput = document.querySelector('#email')
        emailInput.value='';
        const toastClosed = () => {
            console.log('closed')
        }
        toast('Revise su correo para recuperar su clave',{
            position:'top-center',
            onClose: () => navigate('/login') 
        })
        }
    const handleChange = (e) => {
        setEmail(e.target.value);
    }
  return (
    <main className="main-container">
        <div className="login-content">
            <img className="login-logo" src={logo} alt='logo'></img>
            <p className="login-content-text"><span className="login-title">Sistema online exclusivo para PROFESORES</span>
                <br/>
                <br/>

                Hemos diseñado un sistema AUTOGESTIÓN PROFESORES totalmente automatizado para ayudarte en toda tu organización y ahorrarte tiempo. Si tiene una cuenta debe ingresar los datos, sino la tiene, primero debe registrarse y luego recibirá un email para activarla y hacer uso de todas las funciones del sistema.
                Aquí podrá ingresar a todos los participantes, enviar los videos de clasificación, cargar coreografías, adjuntar la música, abonar aranceles y/o adjuntar comprobantes de pago. Una vez finalizada su participación podrá consultar los puntajes, devoluciones de jurados y descargar certificados digitales entre otras cosas.</p>
        </div>
        <div className="login-container-recupero">
            <form className="login-form" id="form" action="#">
                <input className="login-input" type="email" name="email" id="email" placeholder="Email" required onChange={handleChange} ></input>
                <input className="login-button" type="submit" value="Recuperar clave" onClick={handleSubmit}></input>
            </form>
        </div>
        <footer>
            <p className="footer-text">Desarrollado por ZAZ</p>
        </footer>
        <ToastContainer
       
        />
    </main>
  )
}

export default Recupero