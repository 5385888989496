import React, { useEffect, useState } from 'react'
import '../Form.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../../Modal/Modal'
import {Participante as ParticipanteForm} from '../Participante/Participante'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify'

const data = [
  {
    title: "solista",
    categoria: [
      {
        title: "infantil",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 2,
              },
              {
                title:"masculino",
                codigo:1,
              },
              {
                title:"otro"
              }
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"femenino",
                codigo: 3
              },
              {
                title:"otro"
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 12
              },
              {
                title:"masculino",
                codigo: 13
              },
              {
                title:"otro"
              }
            ],
          },
        ]
      },
      {
        title: "junior",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 19
              },
              {
                title:"masculino",
                codigo: 18
              },
              {
                title:"otro"
              }
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"femenino",
                codigo: 20
              },
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 30
              },
              {
                title:"masculino",
                codigo: 31
              },
              {
                title:"otro"
              }
            ],
          },
        ]
      },
      {
        title: "amateur",
        modalidad: [
          {
            title: "salsa sin trucos",
            genero: [
              {
                title:"femenino",
                codigo: 37
              },
              {
                title:"masculino",
                codigo: 36
              },
            ],
          },
          {
            title: "bachata sin trucos",
            genero: [
              {
                title:"femenino",
                codigo: 39
              },
              {
                title:"masculino",
                codigo: 38
              },
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 52
              },
              {
                title:"masculino",
                codigo: 53
              },
            ],
          },
          {
            title: "samba",
            genero: [
              {
                title:"femenino",
                codigo: 58
              },
              {
                title:"masculino",
                codigo: 59
              },
            ],
          },
        ]
      },
      {
        title: "semiprofesional",
        modalidad: [
          {
            title: "Bachata",
            genero: [
              {
                title: "femenino",
                codigo: 75
              },
              {
                title: "masculino",
                codigo: 76
              }
            ]
          },
          {
            title: "salsa",
            genero: [
              {
                title: "femenino",
                codigo: 77
              },
              {
                title: "masculino",
                codigo: 78
              }
            ]
          }
        ]
      },
      {
        title: "profesional",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 84
              },
              {
                title:"masculino",
                codigo: 85
              },
              {
                title:"otro"
              },
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"femenino",
                codigo: 86
              },
              {
                title:"masculino",
                codigo: 87
              },
              {
                title:"otro"
              },
            ],
          },
          {
            title: "samba",
            genero: [
              {
                title:"femenino",
                codigo: 114
              },
              {
                title:"masculino",
                codigo: 113
              },
              {
                title:"otro"
              },
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 108
              },
              {
                title:"masculino",
                codigo: 107
              },
              {
                title:"otro"
              },
            ],
          }
        ]
      },
      {
        title: "capacidades-diferentes",
        modalidad: [
          {
            title: "salsa-o-bachata",
            genero: [
              {
                title: "niños-juveniles-adultos",
                codigo: 121
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "pareja",
    categoria: [
      {
        title: "infantil",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"pareja",
                codigo: 4,
              },
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"pareja",
                codigo: 5
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"pareja",
                codigo: 14
              }
            ],
          },
        ]
      },
      {
        title: "junior",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"pareja",
                codigo: 23
              },
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"pareja",
                codigo: 24
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"pareja",
                codigo: 32
              }
            ],
          },
        ]
      },
      {
        title: "amateur",
        modalidad: [
          {
            title: "salsa sin trucos",
            genero: [
              {
                title:"pareja",
                codigo: 40
              },
            ],
          },
          {
            title: "bachata sin trucos",
            genero: [
              {
                title:"pareja",
                codigo: 41
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"pareja",
                codigo: 54
              }
            ],
          },
          {
            title: "tango",
            genero: [
              {
                title:"pareja",
                codigo: 63
              }
            ],
          },
        ]
      },
      {
        title: "proam",
        modalidad: [
          {
            title: "salsa (alumno-profesora)",
            genero: [
              {
                title:"pareja",
                codigo: 64
              },
            ],
          },
          {
            title: "salsa (profesor-alumna)",
            genero: [
              {
                title:"pareja",
                codigo: 65
              }
            ],
          },
          {
            title: "bachata (alumno-profesora)",
            genero: [
              {
                title:"pareja",
                codigo: 66
              }
            ],
          },
          {
            title: "bachata (profesor-alumna)",
            genero: [
              {
                title:"pareja",
                codigo: 67
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"pareja",
                codigo: 71
              }
            ],
          },
        ]
      },
      {
        title: "semiprofesional",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"pareja",
                codigo: 80
              },
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"pareja",
                codigo: 79
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"pareja",
                codigo: 81
              }
            ],
          }
        ]
      },
      {
        title: "profesional",
        modalidad: [
          {
            title: "salsa on 1 couple",
            genero: [
              {
                title:"pareja",
                codigo: 90
              },
            ],
          },
          {
            title: "salsa on 2 couple",
            genero: [
              {
                title:"pareja",
                codigo: 91
              },
            ],
          },
          {
            title: "salsa cabaret",
            genero: [
              {
                title:"pareja",
                codigo: 92
              },
            ],
          },
          {
            title: "bachata cabaret",
            genero: [
              {
                title:"pareja",
                codigo: 93
              }
            ],
          },
          {
            title: "bachata couple",
            genero: [
              {
                title:"pareja",
                codigo: 94
              }
            ],
          },
          {
            title: "tango",
            genero: [
              {
                title:"pareja",
                codigo: 115
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"pareja",
                codigo: 109
              }
            ],
          }
        ]
      },
      {
        title: "capacidades-diferentes",
        modalidad: [
          {
            title: "salsa-o-bachata",
            genero: [
              {
                title: "niños-juveniles-adultos",
                codigo: 121
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "duo",
    categoria: [
      {
        title: "infantil",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 11
              },
            ],
          },
        ]
      },
      {
        title: "junior",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 22
              },
              {
                title:"masculino",
                codigo: 21
              },
              {
                title:"mixto"
              },
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"mixto"
              },
            ],
          },
        ]
      },
      {
        title: "amateur",
        modalidad: [
          {
            title: "bachata shines",
            genero: [
              {
                title: 'femenino',
                codigo: 47
              },
              {
                title: 'masculino',
                codigo: 48
              }
            ]
          }
        ]
      },
      {
        title: "proam",
        modalidad: [
          {
            title: "bachata",
            genero: [
              {
                title: "femenino",
                codigo: 70
              }
            ]
          }
        ]
      },
      {
        title: "semiprofesional",
        modalidad: [
          {
            title: "bachata",
            genero: [
              {
                title:"femenino"
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 82
              },
            ],
          },
        ]
      },
      {
        title: "profesional",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 88
              },
              {
                title:"masculino",
                codigo: 89
              },
              {
                title:"mixto"
              },
            ],
          },
          {
            title: "bachata shines",
            genero: [
              {
                title:"femenino",
                codigo: 103
              },
              {
                title:"masculino",
                codigo: 104
              },
              {
                title:"mixto"
              },
            ],
          },
        ]
      }
    ]
  },
  {
    title: "grupo",
    categoria: [
      {
        title: "infantil",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 7
              },
              {
                title:"masculino",
                codigo: 6
              },
              {
                title:"pareja",
                codigo: 10
              },
              {
                title: "mixto"
              }
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"femenino",
                codigo: 8
              },
              {
                title:"masculino"
              },
              {
                title:"pareja",
                codigo: 9
              },
              {
                title: "mixto"
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 16
              },
              {
                title:"masculino",
                codigo: 15
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 17
              }
            ],
          },
        ]
      },
      {
        title: "junior",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino",
                codigo: 26
              },
              {
                title:"masculino",
                codigo: 25
              },
              {
                title:"pareja",
                codigo: 29
              },
              {
                title: "mixto"
              }
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"femenino",
                codigo: 27
              },
              {
                title:"pareja",
                codigo: 28
              },
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 34
              },
              {
                title:"masculino",
                codigo: 33
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 35
              }
            ],
          },
        ]
      },
      {
        title: "amateur",
        modalidad: [
          {
            title: "salsa sin trucos",
            genero: [
              {
                title:"femenino",
                codigo: 45
              },
              {
                title:"masculino",
                codigo: 44
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 42
              }
            ],
          },
          {
            title: "bachata sin trucos",
            genero: [
              {
                title:"femenino",
                codigo: 46
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 43
              }
            ],
          },
          {
            title: "bachata shines",
            genero: [
              {
                title: 'femenino',
                codigo: 49
              },
              {
                title: 'masculino',
                codigo: 50
              },
              {
                title: 'mixto',
                codigo: 51
              }
            ]
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 56
              },
              {
                title:"masculino",
                codigo: 55
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 57
              }
            ],
          },
          {
            title: "samba",
            genero: [
              {
                title:"femenino",
                codigo: 60
              },
              {
                title:"masculino",
                codigo: 61
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 62
              }
            ],
          },
        ]
      },
      {
        title: "proam",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja",
                codigo: 68
              },
              {
                title: "mixto"
              }
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja",
                codigo: 69
              },
              {
                title: "mixto"
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 73
              },
              {
                title:"masculino",
                codigo: 72
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 74
              }
            ],
          },
        ]
      },
      {
        title: "semiprofesional",
        modalidad: [
          {
            title: "salsa",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto"
              }
            ],
          },
          {
            title: "bachata",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto"
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto"
              }
            ],
          },
        ]
      },
      {
        title: "profesional",
        modalidad: [
          {
            title: "salsa cabaret",
            genero: [
              {
                title:"femenino",
                codigo: 95
              },
              {
                title:"masculino",
                codigo: 96
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 99
              }
            ],
          },
          {
            title: "salsa no cabaret sin trucos",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 97
              }
            ],
          },
          {
            title: "bachata cabaret",
            genero: [
              {
                title:"femenino",
                codigo: 101
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 100
              }
            ],
          },
          {
            title: "bachata no cabaret sin trucos",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 98
              }
            ],
          },
          {
            title: "salsa shines",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino"
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 102
              }
            ],
          },
          {
            title: "bachata shines",
            genero: [
              {
                title:"femenino"
              },
              {
                title:"masculino",
                codigo: 105
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 106
              }
            ],
          },
          {
            title: "samba",
            genero: [
              {
                title:"femenino",
                codigo: 117
              },
              {
                title:"masculino",
                codigo: 116
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 118
              }
            ],
          },
          {
            title: "urbano",
            genero: [
              {
                title:"femenino",
                codigo: 111
              },
              {
                title:"masculino",
                codigo: 110
              },
              {
                title:"pareja"
              },
              {
                title: "mixto",
                codigo: 112
              }
            ],
          },
        ]
      },
      {
        title: "capacidades-diferentes",
        modalidad: [
          {
            title: "salsa-o-bachata",
            genero: [
              {
                title: "niños-juveniles-adultos",
                codigo: 121
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "exhibicion",
    categoria: [
      {
        title: "todas",
        modalidad: [
          {
            title: "todas",
            genero: [
              {
                title: "todas",
                codigo: 122
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "rueda-de-casino",
    categoria: [
      {
        title: "profesional",
        modalidad: [
          {
            title: "rueda-de-casino",
            genero: [
              {
                title: "-",
                codigo: 83
              }
            ]
          }
        ]

      }
    ]
  }
]
const participantesBase = [
  {
    codigo: 4091,
    nombre: "Guillermo Gomez",
    dni: 27969295,
    edad: 42
  },
  {
    codigo: 4092,
    nombre: "Martin Perez",
    dni: 29834946,
    edad: 39
  },
  {
    codigo: 4093,
    nombre: "Yesica Funes",
    dni: 33461889,
    edad: 34
  }
]

const sedes = [
  'SIN SEDE',
  'FINALISTA NACIONAL',
  'CAMPEON NACIONAL ULTIMA EDICION',
  'BAHIA BLANCA',
  'CORDOBA CAPITAL',
  'JUJUY',
  'MENDOZA',
  'RIO CUARTO',
  'SAN JUAN',
  'VILLA MERCEDES',
  'BUENOS AIRES'
]
const login = {
  academia: '',
  director: '',
  email: '',
  tel: ''
}
const listaPrecios = [
  3000,
  4000,
  5000,
  6000,
  1500,
  2000
]
const edades = {
  infantil: {
    min: 5,
    max: 12
  },
  junior: {
    min: 13,
    max: 17
  }
}

const Coreografia = ({handleCloseMain}) => {
  const [config, setConfig] = useState(data)
  const [participantesSeleccionados, setParticipantesSeleccionados]= useState([])
  const [participantes, setParticipantes]= useState([]);
  const [participantesFiltrados, setParticipantesFiltrados] = useState(participantes)
  const [precio, setPrecio] = useState();
  const [loginData, setLoginData] = useState(login);
  const [formData, setFormData] =useState(loginData);
  const [music, setMusic] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const {auth} = useAuth();


  const modalidad = document.querySelector('#modalidad');
  const categoria = document.querySelector('#categoria');
  const genero = document.querySelector('#genero');
  const formasParticipacion = document.querySelector('#formasParticipacion');
  const eliminatoria = document.querySelector('#eliminatoria');
  const puesto = document.querySelector('#puesto');
  const instancia = document.querySelector('#instancia');
  const monto = document.querySelector('#monto');
  const academia = document.querySelector('#academia');
  const director = document.querySelector('#director');
  const email = document.querySelector('#email');
  const tel = document.querySelector('#tel');
  const musica = document.querySelector('#musica');
  const listadoContainer = document.querySelector('.listadoParticipantes-container');
  const disclaimer = document.querySelector('#disclaimer');

  useEffect(()=> {
    getPrice();
  },[participantesSeleccionados])

  useEffect(()=> {
    getLoginData()
  },[])

  const getLoginData = async () => {
    const login = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}users/me`);
    const {compania, apellido, nombre, email, celular} = login.data;
    const newLogin = {
      academia: compania,
      director: `${apellido}, ${nombre}`,
      email: email,
      tel: celular
    };
    setLoginData(newLogin)
    setFormData(...FormData, newLogin)
  };

  const disableContainer = (state) => {
    const participanteContainer = document.querySelector('.participantes-container');
    const checkboxParticipantes = document.querySelectorAll('.participante-checkbox');
    const cargarParticipante = document.querySelector('#cargarParticipante');
    state?participanteContainer.classList.add('disabled-container'): participanteContainer.classList.remove('disabled-container');
      checkboxParticipantes.forEach ( item => {
        item.disabled = state
      });
      cargarParticipante.disabled = state
    if (state === true) {
      setParticipantesSeleccionados([]);
    }
    
  }
  const clearOptions = (node)=>{
      node.disabled=true;
          while(node.options.length > 0) {
            node.remove(0)
          }
          node.options.add(new Option("Seleccionar...", 'default'));
  }
  const clearSede = (node) => {
    node[0].selected=true;
    node.disabled=true;
  }
  const handleCombo = (value, node, search) => {
    if(value !== 'default') {
      clearOptions(node);
      node.removeAttribute('disabled')
      const filtro = config.find((element)=>element.title===value)
      filtro[node.name].forEach((item)=>{
        node.options.add(new Option(item.title.toUpperCase(), item.title));
      })
    } else {
      clearOptions(node);
    }
  }
  const changeStatePuesto = (state) => {
    for (let i = 1; i<sedes.length-1; i++){
      if (i!==2 && i!==3) {
        puesto[i].disabled= state;
      }
    }
  }
  const handleChange = (e) => {
    const {name, value} = e.target;
    const modalidad = document.querySelector('#modalidad');
    const submodalidad = document.querySelector('#submodalidad');
    const categoria = document.querySelector('#categoria');
    const genero = document.querySelector('#genero');
    const formasParticipacion = document.querySelector('#formasParticipacion');
    const eliminatoria = document.querySelector('#eliminatoria');
    const puesto = document.querySelector('#puesto');
    const instancia = document.querySelector('#instancia');
    const monto = document.querySelector('#monto');
    switch(name) {
      case 'formasParticipacion':
          clearSede(eliminatoria);
          clearSede(puesto);
          clearSede(instancia);
          setPrecio(0);
        if(value !== 'default') {
          disableContainer(false);
          clearOptions(categoria);
          clearOptions(modalidad);
          clearOptions(genero);
          categoria.removeAttribute('disabled')
          const filtro = config.find((element)=>element.title===value)
          filtro[categoria.name].forEach((item)=>{
            categoria.options.add(new Option(item.title.toUpperCase(), item.title));
          })
        } else {
          clearOptions(categoria);
          clearOptions(modalidad);
          clearOptions(genero);
          disableContainer(true);
        }

        break;
      case 'categoria':
        if(value !== 'default') {
          clearOptions(modalidad);
          clearOptions(genero);
          modalidad.removeAttribute('disabled')
          const filtro = config.find((element)=>{
            return element.title===formasParticipacion.value
          }
          )
          const busqueda = filtro.categoria.find((element)=>element.title === value)
          busqueda[modalidad.name].forEach((item)=>{
            modalidad.options.add(new Option(item.title.toUpperCase(), item.title));
          })
          switch(value) {
            case 'infantil':
              const filtrado = participantes.filter(participante => participante.edad >= edades.infantil.min && participante.edad <= edades.infantil.max)
              setParticipantesFiltrados(filtrado);
              break;
            case 'junior':
              const filtradoJunior = participantes.filter(participante => participante.edad >= edades.junior.min && participante.edad <= edades.junior.max)
              setParticipantesFiltrados(filtradoJunior);
              break;
            default:
              setParticipantesFiltrados(participantes);
              break;
          }
        } else {
          clearOptions(modalidad);
          clearOptions(genero);
        }
        break;
      case 'modalidad':
        if(value !== 'default') {
          clearOptions(genero);
          genero.removeAttribute('disabled')
          const filtro = config.find((element)=>element.title===formasParticipacion.value)
          const busqueda = filtro.categoria.find((element)=>element.title === categoria.value)
          const busquedaSub = busqueda.modalidad.find((element)=>element.title === value)
          busquedaSub[genero.name].forEach((item)=>{
            genero.options.add(new Option(`${item.title}${item.codigo? `(${item.codigo})` : ''}` , `${item.title}${item.codigo? `-${item.codigo}` : ''}`));
          })
        } else {
          clearOptions(genero);
        }
        break;
      case 'genero':
        if(value !== 'default') {
          eliminatoria.removeAttribute('disabled')
        } else {
          eliminatoria.disabled=true;
        }
        break;
      case 'eliminatoria':
        switch(value) {
          case 'default':
            puesto.disabled = true
            instancia.disabled =true
            break;
          case 'SIN SEDE':
            puesto.disabled = true
            puesto[0].selected = true;
            instancia[1].selected= true
            instancia.disabled=true
            break;
          case 'FINALISTA NACIONAL':
            puesto.disabled = false;
            changeStatePuesto(true);
            puesto[2].selected = true;
            instancia[2].selected = true;
            instancia.disabled=true;
            break;
          case 'CAMPEON NACIONAL ULTIMA EDICION':
            puesto.disabled = true;
            puesto[0].selected = true;
            instancia[3].selected = true;
            instancia.disabled=true;
            break;
          default:
            puesto.disabled = false;
            changeStatePuesto(false);
            puesto.removeAttribute('disabled')
            instancia[0].selected = true;
            break;
        }
        getPrice();
        break;
      case 'puesto':
        if(value === 'primero'){
          instancia[2].selected=true;
          instancia.disabled= true;
        }else{
          if(value === 'default'){
            instancia[0].selected = true;
            instancia.disabled=true;
          }else{
            if(value === 'segundo' || value === 'tercero'){
              if(eliminatoria.value ==='FINALISTA NACIONAL') {
                instancia[2].selected=true;
                instancia.disabled=true; 
              } else {
                instancia[1].selected=true;
                instancia.disabled=true;
              }
            }else {
              instancia[1].selected=true;
              instancia.disabled=true;
            }
          }
        }
        getPrice();
        break;
      default:
        break;
    }
    setFormData({...formData, [name]: value});
  }
  const getPrice = () => {
    const forma = formasParticipacion?.value;
    const sede = eliminatoria?.value;
    const posicion = puesto?.value;
    switch(forma) {
      case 'solista':
        if(sede=== 'SIN SEDE' || sede === 'CAMPEON NACIONAL ULTIMA EDICION' || sede === 'FINALISTA NACIONAL'){
          setPrecio(listaPrecios[1]);
        }else {
          setPrecio(listaPrecios[0]);
        }
        break;
      case 'pareja':
        if(sede=== 'SIN SEDE' || sede === 'CAMPEON NACIONAL ULTIMA EDICION' || sede === 'FINALISTA NACIONAL'){
          setPrecio(listaPrecios[3]);
        }else {
          setPrecio(listaPrecios[2]);
        }
        break;
      case 'duo':
        if(sede=== 'SIN SEDE' || sede === 'CAMPEON NACIONAL ULTIMA EDICION' || sede === 'FINALISTA NACIONAL'){
          setPrecio(listaPrecios[3]);
        }else {
          setPrecio(listaPrecios[2]);
        }
        break;
      case 'grupo':
        if(sede=== 'SIN SEDE' || sede === 'CAMPEON NACIONAL ULTIMA EDICION' || sede === 'FINALISTA NACIONAL'){
          setPrecio(participantesSeleccionados.length * listaPrecios[5]);
        }else {
          setPrecio(participantesSeleccionados.length * listaPrecios[4]);
        }
        break;
      default:
        setPrecio(0);
        break;
    }
    //monto.textContent=precio;
  }
  const handleChangeMusic = (e) => {
    const musicaNode = document.querySelector('#label-musica-cargada');
    musicaNode.textContent=e.target.files[0].name;

    const preview = document.querySelector('#audioPreview')
    preview.classList.remove('display-none')
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0])
    reader.addEventListener('load', (e) => {
      preview.src = reader.result
    })

    setMusic(e.target.files[0]);
  }
  const handleDisclaimer = (e) => {
    setFormData({...formData, 'disclaimer': e.target.checked})
  }
  const handleChangeHead = (e) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value});
  }
  const handleSubmit = async (e) => {
    if (validateForm()){
      const newData = {...formData, 'eliminatoria': eliminatoria.value, 'monto': monto.textContent, 'instancia': instancia.value, 'participantes': participantesSeleccionados, 'user': auth.id, 'academia': academia.value, 'director': director.value, 'tel': tel.value, 'email': email.value}
      const sendData = new FormData();
      sendData.append('data', JSON.stringify(newData));
      sendData.append('file', music)
      const result = await toast.promise(axiosPrivate.post(`${process.env.REACT_APP_BASE_URI}coreografias/${auth.id}`,sendData, {withCredentials:true, headers:{'Access-Control-Allow-Origin':'*', 'Content-Type':'multipart/form-data; boundary=----ikisdf'}})
      .catch((err)=> {
        toast.error(err.response.data.message)
      }),
      {
        pending: "Registrando Inscripción",
        success: "Registro completo"
      }
      )
      if(result.data.success) {
        handleCloseMain()
      }
    } 
  }
  const handleCheckbox = (e) => {
    const { name, checked} = e.target
    const id = name.substring(name.indexOf('-')+1,name.length);
    if (checked) {
      setParticipantesSeleccionados([...participantesSeleccionados, id])
    } else {
      const filtrado = participantesSeleccionados.filter ( participante => participante !== id)
      setParticipantesSeleccionados(filtrado);
    }
  }
  const handleClose = () => {
    setModalVisible(false);
    getParticipantes();
  }
  const handleCarga = (e) => {
    setModalVisible(true);
  }
  const agregaParticipante = (participante) => {
    //setParticipantes({...participantes, participante})
    const newParticipante = {
      codigo: participantes.length +1,
      nombre: `${participante.apellido}, ${participante.nombre}`,
      dni: participante.dni,
      edad: participante.edad
    }
    setParticipantes([...participantes, newParticipante])
    setModalVisible(false);
  }
  const validateForm = () => {
    const modalidad = document.querySelector('#modalidad');
    const categoria = document.querySelector('#categoria');
    const genero = document.querySelector('#genero');
    const formasParticipacion = document.querySelector('#formasParticipacion');
    const eliminatoria = document.querySelector('#eliminatoria');
    const puesto = document.querySelector('#puesto');
    const instancia = document.querySelector('#instancia');
    const monto = document.querySelector('#monto');
    const academia = document.querySelector('#academia');
    const director = document.querySelector('#director');
    const email = document.querySelector('#email');
    const tel = document.querySelector('#tel');
    const musica = document.querySelector('#file');
    const listadoContainer = document.querySelector('.listadoParticipantes-container');
    const disclaimer = document.querySelector('#disclaimer');
    let validate = true;
    clearMessages();
    if(academia.value === '') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      academia.classList.add('invalidate')
      message.textContent = 'Debe ingresar una academia'
      academia.parentElement.appendChild(message);
      validate=false;
    } else {
      academia.classList.remove('invalidate');
    }
    if(director.value === '') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      director.classList.add('invalidate')
      message.textContent = 'Debe ingresar un director/a'
      director.parentElement.appendChild(message);
      validate=false;
    } else {
      director.classList.remove('invalidate');
    }
    if(email.value === '') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      email.classList.add('invalidate')
      message.textContent = 'Debe ingresar un email'
      email.parentElement.appendChild(message);
      validate=false;
    } else {
      email.classList.remove('invalidate');
    }
    if(tel.value === '') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      tel.classList.add('invalidate')
      message.textContent = 'Debe ingresar un telefono'
      tel.parentElement.appendChild(message);
      validate=false;
    } else {
      tel.classList.remove('invalidate');
    }
    if(formasParticipacion.value === 'default') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      formasParticipacion.classList.add('invalidate')
      message.textContent = 'Debe ingresar una forma de participación'
      formasParticipacion.parentElement.appendChild(message);
      validate=false;
    } else {
      formasParticipacion.classList.remove('invalidate');
    }
    if(categoria.value === 'default') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      categoria.classList.add('invalidate')
      message.textContent = 'Debe ingresar una categoria'
      categoria.parentElement.appendChild(message);
      validate=false;
    } else {
      categoria.classList.remove('invalidate');
    }
    if(modalidad.value === 'default') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      modalidad.classList.add('invalidate')
      message.textContent = 'Debe ingresar una modalidad'
      modalidad.parentElement.appendChild(message);
      validate=false;
    } else {
      modalidad.classList.remove('invalidate');
    }
    if(genero.value === 'default') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      genero.classList.add('invalidate')
      message.textContent = 'Debe ingresar un género'
      genero.parentElement.appendChild(message);
      validate=false;
    } else {
      genero.classList.remove('invalidate');
    }
    if(eliminatoria.value === 'default') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      eliminatoria.classList.add('invalidate')
      message.textContent = 'Debe ingresar una sede'
      eliminatoria.parentElement.appendChild(message);
      validate=false;
    } else {
      eliminatoria.classList.remove('invalidate');
    }
    if(musica.value === '') {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      musica.classList.add('invalidate')
      message.textContent = 'Debe ingresar un tema musical'
      musica.parentElement.appendChild(message);
      validate=false;
    } else {
      musica.classList.remove('invalidate');
    }
    if(participantesSeleccionados.length === 0) {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      listadoContainer.classList.add('invalidate')
      message.textContent = 'Debe ingresar al menos un participante'
      listadoContainer.prepend(message);
      validate=false;
    } else {
      
      if(formasParticipacion.value === 'solista' && participantesSeleccionados.length>1) {
        const message = document.createElement('small')
        message.classList.add('formMessage')
        listadoContainer.classList.add('invalidate')
        message.textContent = 'Debe ingresar solo 1 participante'
        listadoContainer.prepend(message);
        validate=false;
      } else {
        if((formasParticipacion.value === 'pareja' || formasParticipacion.value === 'duo') && participantesSeleccionados.length!==2) {
          const message = document.createElement('small')
          message.classList.add('formMessage')
          listadoContainer.classList.add('invalidate')
          message.textContent = 'Debe ingresar solo 2 participante'
          listadoContainer.prepend(message);
          validate=false;
        } else {
          if(formasParticipacion.value === 'GRUPO' && participantesSeleccionados.length<3) {
            const message = document.createElement('small')
            message.classList.add('formMessage')
            listadoContainer.classList.add('invalidate')
            message.textContent = 'Debe ingresar mas de 2 participantes'
            listadoContainer.prepend(message);
            validate=false;
          }
        }
      }
    }
    if(!disclaimer.checked) {
      const message = document.createElement('small')
      message.classList.add('formMessage')
      disclaimer.classList.add('invalidate')
      message.innerHTML = 'Debe aceptar los terminos<br>'
      disclaimer.parentNode.prepend(message);
      validate=false;
    }
    if(!validate){
      toast.error('Oops... hay errores en algunos campos')
    }

    return validate;
  }
  const clearMessages = () => {
    const messages = document.querySelectorAll ('.formMessage');
    messages.forEach ((message) => {
      message.remove();
    })
  }
  const getParticipantes = async () => {
    const resultParticipantes = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}participantes/${auth.id}`)
          .catch((err)=> {
            toast.error(err.response.data.message)
          });
          if(resultParticipantes?.data?.success){
            setParticipantes(resultParticipantes.data.data)
            setParticipantesFiltrados(resultParticipantes.data.data)
          }
  }
  useEffect(()=> {
    getParticipantes();
  },[])
  return (
    <div>
      <section className="inscripcion-head">
        <div className="alert bg-red">
          <p className="alert-text danger">Antes de iniciar la inscripcion lea atentamente los requisitos. Tenga en cuenta que todos los campos deben ser completados. La categoría del participante se determina por la edad cronológica que tiene al inicio de cada evento inscripto<br></br>Toda la informacion del reglamento, modalidades, categorías, etc. Se pueden consultar en <span className="alert-link">worldlatindance.com.ar</span></p>
        </div>
        <form className="form-container">
          <div className="form-item col-6">
            <label className="form-label" htmlFor="academia">Academia - Ballet *</label>
            <input className="form-input" type="text" name="academia" id="academia" placeholder="Nombre de tu ballet" defaultValue={loginData.academia} onChange={handleChangeHead}></input>
          </div>
          <div className="form-item col-6">
            <label className="form-label" htmlFor="director">Director/a *</label>
            <input className="form-input" type="text" name="director" id="director" placeholder="Ingresar nombre de Director / Representante" defaultValue={loginData.director} onChange={handleChangeHead}></input>
          </div>  
          <div className="form-item col-6">
            <label className="form-label" htmlFor="email">E-mail *</label>
            <input className="form-input" type="email" name="email" id="email" placeholder="E-mail" defaultValue={loginData.email} onChange={handleChangeHead}></input>
          </div>
          <div className="form-item col-6">
            <label className="form-label" htmlFor="tel">Celular/Whatsapp *</label>
            <input className="form-input" type="tel" name="tel" id="tel" placeholder="Telefono" defaultValue={loginData.tel} onChange={handleChangeHead}></input>
          </div>
        </form>
      </section>
      <hr className="section-separator"/>
      <div className="inscripcion-content">
        <section className="formularioCoreografia-container col-8">
          <h1 className="content-subtitle sm-none">Forma de participación</h1>
          <div className="form-participacion-container">
            <div className="form-item col-6">
              <label className="form-label" htmlFor="formasParticipacion">Formas de participación</label>
              <div className="combo-group">
                <select name="formasParticipacion" id="formasParticipacion" className="form-input col-12" onChange={handleChange}>
                  <option value="default">Seleccionar...</option>
                  {config.map((item, index) => {
                    return ( <option value={item.title} key={index} >{item.title.toUpperCase()}</option>)
                  } )}
                </select>
              </div>
            </div>
            <div className="form-item col-6">
              <label className="form-label" htmlFor="categoria">Categoría</label>
              <div className='combo-group'>
                <select name="categoria" id="categoria" className="form-input col-12" disabled onChange={handleChange}>
                  <option value="default">Seleccionar...</option>
                </select>
              </div>
            </div>
            <div className="form-item col-6">
              <label className="form-label" htmlFor="modalidad">Modalidad</label>
              <div className="combo-group">
                <select name="modalidad" id="modalidad" className="form-input col-12" disabled onChange={handleChange}>
                  <option value="default">Seleccionar...</option>
                </select>
              </div>
            </div>
            <div className="form-item col-6">
              <label className="form-label" htmlFor="genero">Genero</label>
              <div className='combo-group'>
                <select name="genero" id="genero" className="form-input col-12" disabled onChange={handleChange}>
                  <option value="default">Seleccionar...</option>
                </select>
              </div>
            </div>
            <div className="form-item col-6">
              <label className="form-label" htmlFor="eliminatoria">Eliminatoria</label>
              <div className="combo-group">
                <select name="eliminatoria" id="eliminatoria" className="form-input col-12" disabled onChange={handleChange}>
                  <option value="default">Seleccionar...</option>
                  {sedes.map((item, index) => {
                    return ( <option value={item} key={index} >{item.toUpperCase()}</option>)
                  } )}
                </select>
              </div>
            </div>
            <input type="hidden" id="codigo" name="codigo" value='0'></input>
            <div className="form-item col-6">
              <label className="form-label" htmlFor="categoria">Puesto</label>
              <div className='combo-group'>
                <select name="puesto" id="puesto" className="form-input col-12" disabled onChange={handleChange}>
                  <option value="default">Seleccionar...</option>
                  <option value="primero">PRIMERO</option>
                  <option value="segundo">SEGUNDO</option>
                  <option value="tercero">TERCERO</option>
                  <option value="cuarto">CUARTO</option>
                  <option value="quinto">QUINTO</option>
                  <option value="sexto">SEXTO</option>
                  <option value="septimo">SEPTIMO</option>
                  <option value="octavo">OCTAVO</option>
                </select>
              </div>
            </div>
            <div className="form-item col-12">
              <label className="form-label label-instancia" htmlFor="instancia">Instancia en la que se inscribe</label>
              <div className="combo-group">
                <select name="instancia" id="instancia" className="form-input col-12 form-instancia" disabled onChange={handleChange}>
                  <option value="default">Seleccionar...</option>
                  <option value="repesca">REPESCA</option>
                  <option value="semifinal">SEMIFINAL</option>
                  <option value="final">FINAL</option>
                </select>
              </div>
              <div className="items-adicionales">
                <div className="form-item">
                  <label className="form-label" id="label-musica" htmlFor="file">Sube tu Musica</label>
                  <small id="label-musica-cargada"></small>
                  <input type="file" name="file" id="file" className="form-input col-12" onChange={handleChangeMusic} accept=".mp3,.mpeg,.obb,.aud,.wav,audio/*"></input>

                  <audio controls="controls" id="audioPreview" className='display-none'>
                        <source src="" type="audio/*"/>
                  </audio> 
                  

                </div>
                <div className="total-container col-12 display-none">
                  <p className="total-title">Total a pagar</p>
                  <h2 className="total-monto">$ <span id='monto'>{precio}</span></h2>
                  <small>El monto está expresado en Pesos Argentinos</small>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className="separator-vertical"/>
        <section className="participantes-container col-5 align-top disabled-container">
          <h1 className="content-subtitle">Seleccione el o los participantes (Seleccionados: <small id='participantesSeleccionados'>{participantesSeleccionados.length}</small> )</h1>
          <div className="listadoParticipantes-container">
            <div className="body-tabla-container">
              <table className="table">
                <thead className="table-header">
                  <tr className="table-row">
                    <th className="table-title"></th>
                    <th className="table-title">#</th>
                    <th className="table-title">Nombre y Apellido</th>
                    <th className="table-title">DNI</th>
                    <th className="table-title">Edad</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    participantesFiltrados.map((participante) => {
                      return (
                        <tr className="table-row">
                          <td className="table-cell"><input type="checkbox" name={`id-${participante._id}`} id={`id-${participante._id}`} onChange={handleCheckbox} className='participante-checkbox'></input> </td>
                          <td className="table-cell">{participante.id}</td>
                          <td className="table-cell">{`${participante.apellido}, ${participante.nombre}`}</td>
                          <td className="table-cell">{participante.dni}</td>
                          <td className="table-cell">{participante.edad}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="button-container">
            <button type="button" className="button" id="cargarParticipante" onClick={handleCarga}>+ CARGAR UN PARTICIPANTE</button>
          </div>
        </section>
      </div>
      <section className="categoria-disclaimer-container">
        
        <input type="checkbox" name="disclaimer" id="disclaimer" onChange={handleDisclaimer}></input>
        <label htmlFor="disclaimer" className='disclaimer-text'>Declaro como profesor/a haber leído, entendido y aceptado todas las bases, condiciones y reglamentos de participación que están visibles en la página oficial y se los he comunicado y explicado a todos mis alumnos participantes como también a sus padres.<br></br>
          Entiendo, estoy de acuerdo y acepto como profesor/a que debo imprimir, leer y firmar, aceptando todos los términos y condiciones, cada una de las páginas de los respectivos contratos de participación que están en la página oficial y le he comunicado a mis alumnos participantes que deben hacer lo mismo. Todos los contratos firmados debo presentarlos en mi turno de acreditación.</label>

          <div className="button-container btn-borrador">
            <button type="button" className="button" id="guardarParticipante" onClick={handleSubmit}>
            <FontAwesomeIcon icon="fa-solid fa-floppy-disk" className='icon-button'/>
              GUARDAR BORRADOR
            </button>
          </div>
      </section>
      <Modal title="INGRESE NUEVOS PARTICIPANTES" visible={modalVisible} handleClose={handleClose} size='small'>
        <ParticipanteForm agregaParticipante={agregaParticipante} handleClose={handleClose}/>
      </Modal>
    </div>
  )
};

export {Coreografia}