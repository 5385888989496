import {createContext,useState} from 'react'

export const UserContext = createContext();


const UserProvider =({children})=>{
    const[token, setToken]=useState()
    const [nombre, setNombre]=useState();
    const [id, setId]=useState();
    
    const setNewToken = (data) => {
        setToken(data)
    }
    const setNewNombre = (data) => {
        setNombre(data)
    }
    const setNewId = (data) => {
        setId(data)
    }
    
    return (
        <UserContext.Provider value={{token, setNewToken, nombre, setNewNombre, id, setNewId}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider