import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Perfil.css'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const data = {
  compania: "",
  nombre: "",
  apellido: "",
  direccion: "",
  localidad: "",
  provincia: "",
  pais: "",
  telefono: "",
  celular: "",
  email: "",
}

const ciudades= [
  'Buenos Aires',
  'Ciudad Autónoma de Buenos Aires',
  'Catamarca',
  'Chaco',
  'Chubut',
  'Córdoba',
  'Corrientes',
  'Entre Ríos',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquén',
  'Río Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucumán'
  ]
  const paises = [
  'Argentina',
  'Bolivia',
  'Brasil',
  'Chile',
  'Colombia',
  'Ecuador',
  'Guyana',
  'Guyana Francesa',
  'Paraguay',
  'Perú',
  'Suriname',
  'Uruguay',
  'Venezuela',
  ]

const Perfil = () => {
  const [formData, setFormData] = useState(data)
  const axiosPrivate = useAxiosPrivate()
  const {auth} = useAuth();
  const navigate = useNavigate();
  
  useEffect(()=> {
    getLoginData()
  },[])

  const getLoginData = async () => {
    const login = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}users/me`);
    setFormData(login.data)
  };


  const handleChange = async (e) => {
    const data = e.target
    setFormData((prevState) => { return {...prevState, [data.name]: data.value};})
  }

  const handleClick = async (e) => {
    console.log('UPDATE', formData)
    const update = await toast.promise(axiosPrivate.put(`${process.env.REACT_APP_BASE_URI}users/${auth.id}`,formData),
      {
        pending: {
          render() {return "Actualizando el perfil"},
          onClose(){ navigate('/')}
        },
        success: "Perfil actualizado"
      })
  }
  return (
    <div className='perfil-container'>
      <h1 className='section-title'>Datos de Usuario</h1>
      <form className="form-container">
      <div className="form-item col-12">
        <label className="form-label" htmlFor="nombre">Compañia</label>
        <input className="form-input m-0" type="text" name="compania" id="compania" placeholder="Nombre de tu compañía" required defaultValue={formData.compania} onChange={handleChange}></input>
      </div>
      <div className="form-item col-6">
        <label className="form-label" htmlFor="nombre">Nombre</label>
        <input className="form-input m-0" type="text" name="nombre" id="nombre" placeholder="Nombre" required defaultValue={formData.nombre} onChange={handleChange}></input>
      </div>
      <div className="form-item col-6">
        <label className="form-label" htmlFor="apellido">Apellido</label>
        <input className="form-input m-0" type="text" name="apellido" id="apellido" placeholder="Apellido" required defaultValue={formData.apellido} onChange={handleChange}></input>
      </div>
      <div className="form-item col-12">
        <label className="form-label" htmlFor="direccion">Dirección</label>
        <input className="form-input m-0" type="text" name="direccion" id="direccion" placeholder="Dirección" required defaultValue={formData.direccion} onChange={handleChange}></input>
      </div>
      <div className="form-item col-4">
        <label className="form-label" htmlFor="localidad">Localidad</label>
        <input className="form-input m-0" type="text" name="localidad" id="localidad" placeholder="Localidad" required defaultValue={formData.localidad} onChange={handleChange}></input>
      </div>
      <div className="form-item col-4">
        <label className="form-label" htmlFor="provincia">Provincia</label>
        <input className="form-input m-0" type="text" name="provincia" id="provincia" placeholder="Provincia" required defaultValue={formData.provincia} onChange={handleChange}></input>
      </div>
      <div className="form-item col-4">
        <label className="form-label" htmlFor="pais">País</label>
        <input className="form-input m-0" type="text" name="pais" id="pais" placeholder="País" required defaultValue={formData.pais} onChange={handleChange}></input>
      </div>
      <div className="form-item col-4">
        <label className="form-label" htmlFor="telefono">Teléfono</label>
        <input className="form-input m-0" type="tel" name="telefono" id="telefono" placeholder="Teléfono" required defaultValue={formData.telefono} onChange={handleChange}></input>
      </div>
      <div className="form-item col-4">
        <label className="form-label" htmlFor="celular">Telefono Alternativo</label>
        <input className="form-input m-0" type="tel" name="celular" id="celular" placeholder="Telefono Alternativo" required defaultValue={formData.celular} onChange={handleChange}></input>
      </div>
      <div className="form-item col-4">
        <label className="form-label" htmlFor="email">E-mail</label>
        <input className="form-input m-0" type="email" name="email" id="email" placeholder="Email" required defaultValue={formData.email} onChange={handleChange}></input>
      </div>
      <div className="form-item col-6 display-none">
        <label className="form-label" htmlFor="password">Cambiar contraseña</label>
        <input className="form-input m-0" type="password" name="password" id="password" placeholder="Clave"></input>
      </div>
      <div className="form-item col-6 display-none">
        <label className="form-label" htmlFor="confirmPassword">Repetir contraseña</label>
        <input className="form-input m-0" type="password" name="confirmPassword" id="confirmPassword" placeholder="Repetir Clave"></input>
      </div>
      <div className="button-container button-modal">
        <button type="button" className="button" id="guardarParticipante" onClick={handleClick}>
          <FontAwesomeIcon icon="fa-solid fa-floppy-disk" className='icon-button'/>
          GUARDAR
        </button>
      </div>
    </form>
    </div>
  )
}

export {Perfil}