import React, { useEffect, useState } from 'react'
import Coreografia from '../Coreografia/Coreografia'
import Participante from '../Forms/Participante/Participante'
import Modal from '../Modal/Modal'
import {Coreografia as CoreografiaForm} from '../Forms/Coreografia/Coreografia'
import './CoreografiaList.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Message from '../Message/Message'
import { Ring } from '@uiball/loaders'
import { useNavigate } from 'react-router-dom'
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import ExportExcel from '../Exports/ExportExcel/ExportExcel'
import logo from '../../assets/images/boton.png'



const CoreografiaList = () => {
  const [modalVisible, setModalVisible]=useState(false);
  const [coreografias, setCoreografias] = useState([]);
  const axiosPrivate = useAxiosPrivate()
  const {auth} = useAuth()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [modalVisiblePopup, setModalVisiblePopup] = useState(true);
  
  const getCoreografias = async () => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}coreografias/${auth.id}`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        });
        if(result?.data?.success){
          setCoreografias(result.data.data);
          setLoading(false)
        }
  }

  const autoClose = () => {
    setTimeout(()=> setModalVisiblePopup(false),8000)
  }

  useEffect(()=>{
    setLoading(true);
    getCoreografias();
    
  },[])
  const handleClose = ()=> {
    setModalVisible(false);
    getCoreografias();
  }
  const handleClosePopup = () => {
    setModalVisiblePopup(false);
  }
  const handleClick = () => {
    setModalVisible(true);
  }
  const handleDelete = async (id) => {
    const result = await toast.promise(axiosPrivate.delete(`${process.env.REACT_APP_BASE_URI}coreografias/${auth.id}/${id}`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        }),
        {
          pending: 'Eliminando inscripcion',
          success: {
            render(){ return'Se eliminó correctamente'},
            theme: 'colored',
          },
          error: {
            render(){return 'Error en la eliminación de la inscripción'},
            theme: 'colored'
          },
        }
        )
        getCoreografias();
  }
  const handleClickButton = async (e) => {
    let id = e.target.id;
    if( id === '') {
      id = e.target.parentNode.id
    }
    const action = id.substring(0, id.indexOf('-'));
    const index = id.substring(id.indexOf('-')+1, id.length);
    switch(action) {
      case 'enviar':
        const result = await toast.promise(axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}coreografias/${auth.id}/send/${index}`)
        .catch((err)=> {
          toast.error(err.response.data.message)
        }),
        {
          pending: 'Enviando inscripcion',
          success: {
            render(){ return'Se envio correctamente'},
            theme: 'colored'
          },
          error: {
            render(){return 'Error en el envio de la inscripción'},
            theme: 'colored'
          },
        }
        )
        if(result.data.success) {
          getCoreografias();
        }
        break;
      case 'print':
        
        break;
      case 'edit':
        break;
      case 'delete':
        toast(<Message handleDelete={handleDelete} id={index}/>)
        break;
      default:
        break;
    }
  }
 
 
  return (
    <>
    {loading? 
              <div class='loader'><Ring/> </div>
            : 
            (
             
                <div className="listadoInscripciones-container">
                   {
                    auth.role==='admin' &&
                    <ExportExcel coreografias={coreografias}/>
                  }
                   {coreografias.length>0 &&
                <div className="body-tabla-container">
                  
                    <table className="table">
                      <thead className="table-header">
                        <tr className="table-row">
                          <th className="table-title">#</th>
                          <th className="table-title">Modalidad</th>
                          <th className="table-title">Forma</th>
                          <th className="table-title">Categoría</th>
                          <th className="table-title">Participantes</th>
                          <th className="table-title display-none">Precio</th>
                          {auth.role === 'admin' && <th className='table-title'>Academia</th>}
                          <th className="table-title">Acciones</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {coreografias.map((coreografia, index) => {
                        return <Coreografia data={coreografia} key={index} handleClick={handleClickButton}/>
                        })}
                      </tbody>
                    </table>
                  </div>
}
                  {
                    auth.role==='admin' &&
                    <button type="button" className="button" id="cargarCoreografia" onClick={handleClick}>+ CARGAR NUEVA COREOGRAFIA</button>
                  }
                  <Modal title="INSCRIPCION CAMPEONATO ARGENTINA WORLD LATIN DANCE CUP" visible={modalVisible} handleClose={handleClose}>
                    <CoreografiaForm handleCloseMain={handleClose}/>
                  </Modal>
                  <Modal title="RECORDATORIO" visible={modalVisiblePopup} handleClose={handleClosePopup} size='small'>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>Recuerde que luego de guardar la coreografía, <br></br>DEBE SER ENVIADA desde el listado de coreografias con el boton ENVIAR </h1>
                    <h1>Una vez enviada se cambiará el color a Verde</h1>
                    <img src={logo}></img>

                  </Modal>

                </div>
  )
    }
    </>
  )
}

export default CoreografiaList