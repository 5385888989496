import Modal from '../Modal/Modal'
import Participante from '../Participante/Participante'
import {Participante as ParticipanteForm} from '../Forms/Participante/Participante'
import './ParticipanteList.css'
import { useEffect, useState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import { toast } from 'react-toastify'
import Message from '../Message/Message'
import { Ring } from '@uiball/loaders'
import fileDownload from 'js-file-download'

import XLSX from 'sheetjs-style';
import { Link } from 'react-router-dom'


const ParticipanteList = () => {
  const [modalVisible, setModalVisible]=useState(false);
  const [participantes, setParticipantes]= useState([])
  const [filteredParticipantes, setFilteredParticipantes] = useState(participantes)
  const [totalRegistros, setTotalRegistros] = useState(participantes.length)
  const [totalRegistrosMostrados, setTotalRegistrosMostrados] = useState(filteredParticipantes.length)
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const {auth} = useAuth();
  const downloadPath = 'https://api.awldc.dalproducciones.com.ar/files/ListadoParticipantes.pdf';

  const handleClose = ()=> {
    setModalVisible(false);
    getParticipantes();
  }
  const handleClick = () => {
    setModalVisible(true);
  }

  const getParticipantes = async () => {
    const resultParticipantes = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}participantes/${auth.id}`)
          .catch((err)=> {
            console.log('ERROR EN GET', err)
          });
          if(resultParticipantes?.data?.success){
            setParticipantes(resultParticipantes.data.data)
            setFilteredParticipantes(resultParticipantes.data.data)
            setTotalRegistros(resultParticipantes.data.data.length)
            setTotalRegistrosMostrados(resultParticipantes.data.data.length)
            setLoading(false)
          }
  }
  useEffect(()=>{
    getParticipantes();
  },[])
  const handleSearch = (e) => {
    const {value} = e.target
    let filtrado
    if(value !== '') {
      filtrado = participantes.filter(participante => {
        if (participante.dni.toString().indexOf(value) !== -1 || participante.nombre.toLowerCase().indexOf(value.toLowerCase()) !== -1 ){
          return participante
        }
      })
    }else {
      filtrado = participantes
    }
    setTotalRegistrosMostrados(filtrado.length)
    setFilteredParticipantes(filtrado);
  }
  const handleClickButton = (e) => {
    e.stopPropagation()
    let id='';
    if(e.target.id) {
      id = e.target.id;
    }else {
      id = e.target.parentNode.parentNode.id;
    }
    const index = id.substring(id.indexOf('-')+1, id.length)
    const action = id.substring(0, id.indexOf('-'));
    
    switch(action) {
      case 'eliminar':
        toast(<Message handleDelete={handleDelete} id={index}/>)
        break;
      case 'editar':
        break;
      default:
        break;
    }
  }

  const handleDelete = async (id) => {
    const result = await toast.promise(axiosPrivate.delete(`${process.env.REACT_APP_BASE_URI}participantes/${auth.id}/${id}`)
        .catch((err)=> {
          console.log('ERROR CARGA',err)
        }),
        {
          pending: 'Eliminando participante',
          success: {
            render(){ return'Se eliminó correctamente'},
            theme: 'colored'
          },
          error: {
            render(){return 'Error en la eliminación del participante'},
            theme: 'colored'
          },
        }
        )
        getParticipantes();
  }

  const exportToExcel = async (fileName) => {
    const dataToExport = filteredParticipantes.map(data => ({
      id: data.id,
      name: `${data.nombre} ${data.apellido}`,
      dni: data.dni,
      fechaNacimiento: data.fechaNacimiento,
      edad: data.edad,
      academia: data.user.academia
    }))
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = {Sheets: {'data': ws}, SheetsNames: ['data']};
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Dates");
    XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });
    // const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    // const data = new Blob([excelBuffer], {type: fileType});
    // FileSaver.saveAs(data, fileName + fileExtension);
  }

  const generarListado = async() => {
    const result = await toast.promise(axiosPrivate.get(`${process.env.REACT_APP_BASE_URI}participantes/listado`),
    {
      pending: "Generando Listado",
      success: "Generado correctamente"
    }

    ) 
    if(result){
      console.log(result.data.data.data);
      downloadFile(downloadPath)
    }
  }

  function downloadFile(filePath){
    var link=document.createElement('a');
    link.href = filePath;
    link.target = '_blank';
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
    document.removeChild(link);
}

  return (
    <>
      {loading? 
              <div class='loader'><Ring/> </div>
            : 
    <section className="participantes-container p-0">
      <h1 className="section-title">CAMPEONATO ARGENTINA WORLD LATIN DANCE CUP</h1>
      
      <h2 className="section-subtitle">PARTICIPANTES CARGADOS EN EL SISTEMA</h2>
      {
        auth.role==='admin' &&
        <div className="button-container">
          <button className='button' onClick={generarListado}>
            Descargar Listado
          </button>
        </div>
      }
      {
        filteredParticipantes.length > 0 &&
        <div className="listadoParticipantes-container">
          <div className="header-tabla-container">
            <div className="header-mostrar">
              <p>
                Mostrar 
                <select name="mostrarRegistros" className="mostrarRegistros">
                  <option value="10" selected>10</option>
                  <option value="50" >50</option>
                  <option value="100">100</option>
                </select>
                registros
              </p>
            </div>
            <div className="header-buscar">
              <label for="buscar">Buscar: </label>
              <input type="text" name="buscar" id="buscar" onChange={handleSearch} placeholder='por DNI o Nombre o Apellido'></input>
            </div>
          </div>
          <div className="body-tabla-container">
          
            <table className="table">
              <thead className="table-header">
                <tr className="table-row">
                  <th className="table-title col-1">#</th>
                  <th className="table-title">Nombre y Apellido</th>
                  <th className="table-title col-1">DNI</th>
                  <th className="table-title col-2">Fecha de nacimiento</th>
                  <th className="table-title col-1">Edad</th>
                  {auth.role === 'admin' && <th className="table-title col-1">Academia</th>}
                  <th className="table-title col-2">Acciones</th>
                </tr>
              </thead>
              
                <tbody className="table-body">
                  {
                    filteredParticipantes.map((participante) => {
                      return(
                        <Participante data={participante} handleClick={handleClickButton} />
                      )
                    })
                  }
                </tbody>
            
            </table>
            
          </div>
          <div className="table-footer">
            <div className="mostrar-text"><p>Mostrando {totalRegistrosMostrados} registros de un total de {totalRegistros} registros</p></div>
            <div className="pagination-container">
              <button type="button" className="pagination-button-text">Anterior</button>
              <button type="button" className="pagination-button">1</button>
              <button type="button" className="pagination-button-text">Posterior</button>
            </div>
          </div>
          
        </div>
      }
      {
        auth.role==='admin' &&
        <div className="button-container">
        <button type="button" className="button" id="cargarParticipante" onClick={handleClick}>+ CARGAR UN PARTICIPANTE</button>
      </div>
      }
     
  </section>
    }
  <Modal title="AGREGAR PARTICIPANTES" visible={modalVisible} handleClose={handleClose}>
    <ParticipanteForm handleClose={handleClose}/>
  </Modal>
</>
  
  )
}

export default ParticipanteList