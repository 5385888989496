import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Footer from '../Footer/Footer'
import Navbar from './Navbar/Navbar'
import Sidebar from './Sidebar/Sidebar'

const Layout = () => {
  return (
    <>
      <Navbar/>
      <Sidebar>
        <Outlet/>
      </Sidebar>
      <Footer/>
      <ToastContainer />
    </>
  )
}

export default Layout