import React, { useState } from 'react'
import '../Form.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-toastify';

const Participante = ({handleClose}) => {
  const [formData, setFormData] = useState({})
  const axiosPrivate = useAxiosPrivate()
  const {auth} = useAuth(); 

  const handleClick = async () => {
      const newData = {...formData, user: auth.id}
      const result = await toast.promise(axiosPrivate.post(`${process.env.REACT_APP_BASE_URI}participantes/${auth.id}`,JSON.stringify(newData), {withCredentials:true, headers:{'Access-Control-Allow-Origin':'*', 'Content-Type':'application/json'}})
        .catch((err)=> {
          toast.error(err.response.data.message)
        }),{
          pending: "Aguarde mientras se genera el participante",
          success: "Participante generado exitosamente"
        });
        if(result.data.success) {
          handleClose()
        }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value});
    const hoy = dayjs();
    if (name==='fechaNacimiento') {
      const nacimiento = dayjs(value)
      const edad = hoy.diff(nacimiento, 'year');
      const edadContainer = document.querySelector('#edad')
      edadContainer.value = edad;
      setFormData({...formData, 'edad': edad, 'fechaNacimiento': nacimiento.format('DD-MM-YYYY')});
    }
  }

  return (
    <form className="form-container">
      <div className="form-item col-6">
        <label className="form-label" htmlFor="nombre">Nombre</label>
        <input className="form-input" type="text" name="nombre" id="nombre" onChange={handleChange}></input>
      </div>
      <div className="form-item col-6">
        <label className="form-label" htmlFor="apellido">Apellido</label>
        <input className="form-input" type="text" name="apellido" id="apellido" onChange={handleChange}></input>
      </div>
      <div className="form-item col-4">
        <label className="form-label" htmlFor="dni">DNI</label>
        <input className="form-input" type="text" name="dni" id="dni" onChange={handleChange}></input>
      </div>
      <div className="form-item col-3">
        <label className="form-label" htmlFor="fechaNacimiento">Fecha de Nacimiento</label>
        <input className="form-input" type="date" name="fechaNacimiento" id="fechaNacimiento" onChange={handleChange}></input>
      </div>
      <div className="form-item col-3">
        <label className="form-label" htmlFor="edad">Edad</label>
        <input className="form-input" type="text" name="edad" id="edad" disabled></input>
      </div>
      <div className="button-container button-modal">
        <button type="button" className="button" id="guardarParticipante" onClick={handleClick}>
          <FontAwesomeIcon icon="fa-solid fa-floppy-disk" className='icon-button'/>
          GUARDAR
        </button>
      </div>
    </form>
  )
}

export {Participante}