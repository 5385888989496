import React from 'react'
import './Registro.css'
import logo from '../../assets/images/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import {useFormik} from 'formik';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import { faIndianRupee } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';

const REGISTER_URL = 'users/signup';
const validationSchema = yup.object({
    email: yup
        .string('Ingrese email')
        .required('Es necesario que ingrese su email'),
    password: yup
        .string('Ingrese su contraseña')
        .min(6,'Password debe tener 6 caracteres como mínimo')
        .required('Es necesario que ingrese su password')
})
const ciudades= [
  'Seleccionar...',
  'Buenos Aires',
  'Ciudad Autónoma de Buenos Aires',
  'Catamarca',
  'Chaco',
  'Chubut',
  'Córdoba',
  'Corrientes',
  'Entre Ríos',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquén',
  'Río Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucumán'
  ]
  const paises = [
  'Argentina',
  'Bolivia',
  'Brasil',
  'Chile',
  'Colombia',
  'Ecuador',
  'Guyana',
  'Guyana Francesa',
  'Paraguay',
  'Perú',
  'Suriname',
  'Uruguay',
  'Venezuela',
  ]


const Register = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      compania:'',
      nombre:'',
      apellido:'',
      direccion:'',
      localidad:'',
      provincia:'',
      pais:'Argentina',
      telefono:'',
      celular:'',
      email:'',
      password:'',

      confirmPassword:''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await toast.promise(axios.post(`${process.env.REACT_APP_BASE_URI}${REGISTER_URL}`,JSON.stringify(values,null,2), {withCredentials:true, headers:{'Access-Control-Allow-Origin':'*', 'Content-Type':'application/json'}})
        .catch((err)=> {
          toast.error(err.response.data.message);
        }),{
          pending: 'Registrando...'
        });
        result && navigate('/success');
    }
  })


  return (
    <>
      <header id="cabecera">
          <a id="logo-cabecera" href='/'>
              <img className="logo-cabecera" src={logo} alt='logo'></img>
          </a> 
          <p className="titulo-cabecera"><span className="titulo-pagina-costado">DATOS DE REGISTRO</span></p>
      </header>
    <div className="main-container">
        <div className="register-container">
            <form className="register-form" id="form" onSubmit={formik.handleSubmit}>
                <input className="login-input" type="text" name="compania" id="compania" placeholder="Academia, compañia, bailarin/a independiente" onChange={formik.handleChange} required minLength={3}></input>
                <input className="register login-input" type="text" name="nombre" id="nombre" placeholder="Nombre" onChange={formik.handleChange} required></input>
                <input className="register login-input" type="text" name="apellido" id="apellido" placeholder="Apellido" onChange={formik.handleChange} required></input>
                <input className="register login-input" type="text" name="direccion" id="direccion" placeholder="Dirección" onChange={formik.handleChange} required></input>
                <input className="register login-input" type="text" name="localidad" id="localidad" placeholder="Localidad" onChange={formik.handleChange} required></input>
                
                {formik.values.pais === 'Argentina'? 
                  <select name="provincia" id="provincia" className="register login-input" onChange={formik.handleChange} required>
                    {ciudades.map((item, index) => {
                      return ( <option value={item} key={index} >{item.toUpperCase()}</option>)
                    } )}
                  </select>
                :
                  <input className="register login-input" type="text" name="provincia" id="provincia" placeholder="Provincia" onChange={formik.handleChange} required></input>
                }
                <select name="pais" id="pais" className="register login-input" onChange={formik.handleChange} required >
                          {paises.map((item, index) => {
                            return ( <option value={item} key={index} >{item.toUpperCase()}</option>)
                          } )}
                </select>
                <input className="register login-input" type="tel" name="telefono" id="telefono" placeholder="Teléfono" onChange={formik.handleChange} required></input>
                <input className="register login-input" type="tel" name="celular" id="celular" placeholder="Teléfono Alternativo" onChange={formik.handleChange} required></input>
                <input className="login-input" type="email" name="email" id="email" placeholder="Email" onChange={formik.handleChange} required></input>
                <input className="register login-input" type="password" name="password" id="password" placeholder="Clave" onChange={formik.handleChange} required></input>
                <input className="register login-input" type="password" name="confirmPassword" id="confirmPassword" placeholder="Repetir Clave" onChange={formik.handleChange} required minLength={8}></input>
                <input className="register login-button" type="submit" value="Registrar"></input>
            </form>
        </div>
        <footer>
            <p className="footer-text">Desarrollado por ZAZ</p>
        </footer>
    <ToastContainer/>
    </div>
    </>
  )
}

export default Register